import { Link } from 'react-router-dom'
import SwiperCore, { Autoplay, EffectCoverflow } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/effect-coverflow'
import 'swiper/css/pagination'

import VanillaTilt from 'vanilla-tilt'

const list = [
  { name: 'Charlie Lee', info: 'Cryptocurrency Enthusiast', img: <div className='card-team-img bg-[url("./image/Team/Charlie.png")]' /> },
  { name: 'Seihaku Yoshida', info: 'Foodie in Crypto', img: <div className='card-team-img bg-[url("./image/Team/Yoshida.png")]' /> },
  { name: 'Yijin Li', info: 'Chinese Chives', img: <div className='card-team-img bg-[url("./image/Team/Yijin.png")]' /> },
  { name: 'Michael Ou', info: 'Mike with Mic', img: <div className='card-team-img bg-[url("./image/Team/Micheal.png")]' /> },
  { name: 'Kefei Lin', info: 'Entreprenerd', img: <div className='card-team-img bg-[url("./image/Team/Kefei.png")]' /> },
  { name: 'Jack Jia', info: 'Web3 Shopaholic', img: <div className='card-team-img bg-[url("./image/Team/jack.png")]' /> },
  { name: 'Andy Tang', info: 'Hermes Lover', img: <div className='card-team-img bg-[url("./image/Team/Andy.png")]' /> },
  { name: 'Dr Nerd', info: 'Dr Nerd', img: <div className='card-team-img bg-[url("./image/Team/Eric.png")]' /> }
]

export default function HomeTeam () {
  const teamCoverflow = (
    <Swiper
        onTransitionStart={() => {
          const cardTeamImage = document.querySelectorAll('.card-team-img')
          cardTeamImage.forEach(ele => ele.vanillaTilt?.destroy())
        }}
        onTransitionEnd={() => {
          VanillaTilt.init(document.querySelector('.swiper-slide-active .card-team-img'))
        }}
        effect={'coverflow'}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={2}
        initialSlide={Math.floor(Math.random() * list.length)}
        spaceBetween={0}
        coverflowEffect={{
          rotate: 20,
          stretch: 30,
          depth: 200,
          modifier: 1.3,
          slideShadows: true
        }}
        breakpoints={{
          1500: {
            slidesPerView: 5
          },
          1280: {
            slidesPerView: 4
          },
          768: {
            coverflowEffect: {
              rotate: 10,
              stretch: 100,
              depth: 200,
              modifier: 2,
              slideShadows: true
            }
          },
          600: {
            coverflowEffect: {
              rotate: 13,
              stretch: 60,
              depth: 180,
              modifier: 2,
              slideShadows: true,
              slidesPerView: 3
            }
          },
          480: {
            coverflowEffect: {
              rotate: 15,
              stretch: 50,
              depth: 150,
              modifier: 2,
              slideShadows: true
            }
          }
        }}
        modules={[EffectCoverflow, Autoplay]}
        autoplay={{ delay: 3000, disableOnInteraction: true }}
    >
      {list.map(({ name, info, img }) => {
        return (
        <SwiperSlide key={name}>
          <Card name={name} info={info} img={img}/>
        </SwiperSlide>)
      })}
    </Swiper>
  )

  return (
    <>
      <div id="scroll-element-team" className=' w-0 h-0 opacity-0'></div>
      <div id="homeTeamTarget" data-aos="zoom-in" data-aos-duration="1500">
        <div className='overflow-hidden overflow-x-auto'>
          <div className='py-40 text-white'>
            { teamCoverflow }
          </div>
        </div>
      </div>
    </>
  )
}

function Card ({ name, info, img }) {
  return (
  <div className='card-team'>
   {img}
    <div className='card-team-content'>
      <h4 className='card-team-title'>{name}</h4>
    </div>
    <div className='card-team-info'>
      <p className='card-team-info-txt'>
       {info}
      </p>
      <div className='card-team-info-icon'>
        {/* <Link to='/'>
          <svg className='card-team-icon-info-img'
            width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M24 2.36737C22.5202 3.16215 22.1682 3.04076 21.1719 3.16215C22.1887 2.53772 22.9691 1.54861 23.3369 0.368668C22.3855 0.947647 21.3322 1.36777 20.2102 1.59456C19.3123 0.613448 18.0325 0 16.6163 0C13.8973 0 11.6923 2.26097 11.6923 5.04896C11.6923 5.4446 11.7362 5.82975 11.82 6.19942C7.72767 5.98911 4.09914 3.97942 1.67103 0.924168C1.24718 1.67 1.00408 2.53672 1.00408 3.46238C1.00408 5.21431 1.8737 6.75942 3.19445 7.6651C2.38767 7.63863 1.62816 7.41183 0.964618 7.03317C0.964131 7.05415 0.964131 7.07563 0.964131 7.09711C0.964131 9.54291 2.66147 11.5836 4.9142 12.0477C4.50107 12.1631 4.06601 12.225 3.61683 12.225C3.29919 12.225 2.9908 12.1935 2.69021 12.1341C3.31722 14.1398 5.13538 15.6 7.28969 15.6404C5.60453 16.9947 3.4814 17.802 1.17459 17.802C0.777054 17.802 0.38536 17.778 0 17.7315C2.17916 19.1643 4.76755 20 7.5479 20C16.6051 20 21.5573 12.3064 21.5573 5.63443C21.5573 5.41563 21.5529 5.19732 21.5436 4.98102C22.5053 4.26966 23.3404 3.37996 24 2.36737Z" fill="currentcolor" />
          </svg>
        </Link>
        <Link to='/'>
          <svg className='card-team-icon-info-img'
            width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.64987 9.57024C8.09231 6.66716 12.3883 4.75327 14.5378 3.82857C20.675 1.18838 21.9503 0.729747 22.7815 0.714441C22.9643 0.711272 23.3731 0.758132 23.6379 0.980346C23.8614 1.16798 23.9229 1.42144 23.9524 1.59934C23.9818 1.77724 24.0185 2.18249 23.9893 2.49914C23.6567 6.11337 22.2177 14.8842 21.4856 18.9322C21.1758 20.645 20.5658 21.2193 19.9753 21.2755C18.6919 21.3977 17.7174 20.3983 16.4744 19.5556C14.5294 18.2369 13.4305 17.416 11.5425 16.1292C9.36064 14.6421 10.7751 13.8247 12.0185 12.4889C12.344 12.1393 17.9985 6.81984 18.1079 6.33727C18.1216 6.27692 18.1343 6.05196 18.0051 5.93317C17.8759 5.81438 17.6851 5.855 17.5475 5.88731C17.3524 5.9331 14.2452 8.05726 8.22584 12.2598C7.34386 12.8862 6.54499 13.1914 5.82924 13.1754C5.04017 13.1578 3.52233 12.7139 2.39397 12.3346C1.00999 11.8693 -0.0899675 11.6233 0.00581422 10.8331C0.0557033 10.4215 0.603724 10.0005 1.64987 9.57024Z" fill="currentcolor" />
          </svg>
        </Link>

        <Link to='/' >
          <svg className='card-team-icon-info-img' width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.7561 0.25293C9.41026 0.25293 8.07762 0.518009 6.83424 1.03303C5.59086 1.54806 4.4611 2.30294 3.50946 3.25458C1.58754 5.1765 0.507813 7.78319 0.507812 10.5012C0.507813 15.0309 3.44907 18.874 7.51763 20.2371C8.03004 20.319 8.19402 20.0013 8.19402 19.7246V17.9927C5.35524 18.6076 4.7506 16.6194 4.7506 16.6194C4.27918 15.4306 3.61304 15.1129 3.61304 15.1129C2.68045 14.4775 3.68478 14.498 3.68478 14.498C4.7096 14.5698 5.25276 15.5536 5.25276 15.5536C6.14436 17.1113 7.65086 16.6502 8.23501 16.4042C8.32724 15.7381 8.5937 15.2871 8.88065 15.0309C6.60553 14.7747 4.21769 13.8934 4.21769 9.98879C4.21769 8.85123 4.60712 7.93913 5.27326 7.21151C5.17078 6.9553 4.81209 5.88948 5.37574 4.50596C5.37574 4.50596 6.2366 4.22926 8.19402 5.55129C9.00363 5.32582 9.88498 5.21309 10.7561 5.21309C11.6272 5.21309 12.5085 5.32582 13.3182 5.55129C15.2756 4.22926 16.1364 4.50596 16.1364 4.50596C16.7001 5.88948 16.3414 6.9553 16.2389 7.21151C16.905 7.93913 17.2945 8.85123 17.2945 9.98879C17.2945 13.9036 14.8964 14.7645 12.611 15.0207C12.98 15.3384 13.3182 15.9635 13.3182 16.9166V19.7246C13.3182 20.0013 13.4821 20.3293 14.0048 20.2371C18.0733 18.8638 21.0044 15.0309 21.0044 10.5012C21.0044 9.15538 20.7393 7.82273 20.2243 6.57936C19.7092 5.33598 18.9543 4.20622 18.0027 3.25458C17.0511 2.30294 15.9213 1.54806 14.6779 1.03303C13.4345 0.518009 12.1019 0.25293 10.7561 0.25293V0.25293Z" fill="#00FFFF"/>
          </svg>
        </Link>
        <Link to='/'>
          <svg className='card-team-icon-info-img' width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.7817 0.25293C18.3711 0.25293 18.9363 0.487056 19.353 0.903803C19.7698 1.32055 20.0039 1.88578 20.0039 2.47515V18.0307C20.0039 18.6201 19.7698 19.1853 19.353 19.6021C18.9363 20.0188 18.3711 20.2529 17.7817 20.2529H2.22613C1.63676 20.2529 1.07153 20.0188 0.65478 19.6021C0.238032 19.1853 0.00390625 18.6201 0.00390625 18.0307V2.47515C0.00390625 1.88578 0.238032 1.32055 0.65478 0.903803C1.07153 0.487056 1.63676 0.25293 2.22613 0.25293H17.7817ZM17.2261 17.4752V11.5863C17.2261 10.6256 16.8445 9.70426 16.1652 9.02497C15.4859 8.34567 14.5646 7.96404 13.6039 7.96404C12.6595 7.96404 11.5595 8.54182 11.0261 9.40849V8.17515H7.92613V17.4752H11.0261V11.9974C11.0261 11.1418 11.715 10.4418 12.5706 10.4418C12.9831 10.4418 13.3788 10.6057 13.6705 10.8974C13.9622 11.1892 14.1261 11.5848 14.1261 11.9974V17.4752H17.2261ZM4.31502 6.43071C4.81009 6.43071 5.28488 6.23404 5.63495 5.88397C5.98502 5.53391 6.18168 5.05911 6.18168 4.56404C6.18168 3.53071 5.34835 2.68626 4.31502 2.68626C3.817 2.68626 3.33938 2.8841 2.98723 3.23625C2.63508 3.5884 2.43724 4.06602 2.43724 4.56404C2.43724 5.59737 3.28168 6.43071 4.31502 6.43071ZM5.85946 17.4752V8.17515H2.78168V17.4752H5.85946Z" fill="#00FFFF"/>
          </svg>
        </Link>
        <Link to='/'>
          <svg className='card-team-icon-info-img' width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.3639 12.2529C14.4439 11.5929 14.5039 10.9329 14.5039 10.2529C14.5039 9.57293 14.4439 8.91293 14.3639 8.25293H17.7439C17.9039 8.89293 18.0039 9.56293 18.0039 10.2529C18.0039 10.9429 17.9039 11.6129 17.7439 12.2529M12.5939 17.8129C13.1939 16.7029 13.6539 15.5029 13.9739 14.2529H16.9239C15.9551 15.9212 14.418 17.1849 12.5939 17.8129ZM12.3439 12.2529H7.66391C7.56391 11.5929 7.50391 10.9329 7.50391 10.2529C7.50391 9.57293 7.56391 8.90293 7.66391 8.25293H12.3439C12.4339 8.90293 12.5039 9.57293 12.5039 10.2529C12.5039 10.9329 12.4339 11.5929 12.3439 12.2529ZM10.0039 18.2129C9.17391 17.0129 8.50391 15.6829 8.09391 14.2529H11.9139C11.5039 15.6829 10.8339 17.0129 10.0039 18.2129ZM6.00391 6.25293H3.08391C4.04276 4.58014 5.5787 3.31442 7.40391 2.69293C6.80391 3.80293 6.35391 5.00293 6.00391 6.25293ZM3.08391 14.2529H6.00391C6.35391 15.5029 6.80391 16.7029 7.40391 17.8129C5.58253 17.1846 4.04875 15.9206 3.08391 14.2529ZM2.26391 12.2529C2.10391 11.6129 2.00391 10.9429 2.00391 10.2529C2.00391 9.56293 2.10391 8.89293 2.26391 8.25293H5.64391C5.56391 8.91293 5.50391 9.57293 5.50391 10.2529C5.50391 10.9329 5.56391 11.5929 5.64391 12.2529M10.0039 2.28293C10.8339 3.48293 11.5039 4.82293 11.9139 6.25293H8.09391C8.50391 4.82293 9.17391 3.48293 10.0039 2.28293ZM16.9239 6.25293H13.9739C13.6609 5.01439 13.1975 3.81883 12.5939 2.69293C14.4339 3.32293 15.9639 4.59293 16.9239 6.25293ZM10.0039 0.25293C4.47391 0.25293 0.00390625 4.75293 0.00390625 10.2529C0.00390625 12.9051 1.05747 15.4486 2.93284 17.324C3.86142 18.2526 4.96382 18.9892 6.17707 19.4917C7.39033 19.9943 8.69069 20.2529 10.0039 20.2529C12.6561 20.2529 15.1996 19.1994 17.075 17.324C18.9503 15.4486 20.0039 12.9051 20.0039 10.2529C20.0039 8.93971 19.7452 7.63935 19.2427 6.4261C18.7402 5.21284 18.0036 4.11045 17.075 3.18186C16.1464 2.25328 15.044 1.51668 13.8307 1.01413C12.6175 0.511587 11.3171 0.25293 10.0039 0.25293Z" fill="#00FFFF"/>
          </svg>
        </Link> */}
      </div>
    </div>
  </div>
  )
}
